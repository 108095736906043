<template>
  <div class="mt-5">
    <p class="lead">Fale Conosco</p>

    <p class="text-muted">
      Preencha o formulário abaixo com suas dúvidas para retornamos seu contato
      ou ligue-nos.
    </p>

    <p class="mb-0">Telefones:</p>
    <p class="m-0 text-muted">Administrativo: (11) 2946-8338</p>
    <p class="m-0 text-muted">Vendas: (11) 2893-5323 | (11) 2936-7454</p>
    <p class="m-0 text-muted">Qualidade: (11) 2264-0881</p>
    <p class="mt-0 text-muted">Whatsapp: (11) 9 8184-3665</p>

    <form ref="form" class="mt-5" @submit.stop.prevent="handleSubmit">
      <div class="row">
        <!-- NOME -->
        <b-form-group
          :state="state.nome"
          label="Nome"
          label-for="nome"
          invalid-feedback="Nome é obrigatório"
          class="col-12 col-md-4"
        >
          <b-form-input
            id="nome"
            v-model="formulario.nome"
            :state="state.nome"
            required
          ></b-form-input>
        </b-form-group>

        <!-- EMAIL -->
        <b-form-group
          :state="state.email"
          label="E-mail"
          label-for="email"
          invalid-feedback="E-mail é obrigatório"
          class="col-12 col-md-4"
        >
          <b-form-input
            id="email"
            v-model="formulario.email"
            :state="state.email"
            type="email"
            required
          >
          </b-form-input>
        </b-form-group>

        <!-- TELEFONE -->
        <b-form-group
          :state="state.telefone"
          label="Telefone"
          label-for="telefone"
          invalid-feedback="Telefone é obrigatório"
          class="col-12 col-md-4"
        >
          <b-form-input
            id="telefone"
            v-model="formulario.telefone"
            :state="state.telefone"
            v-mask="['(##) ####-####', '(##) #####-####']"
            required
          >
          </b-form-input>
        </b-form-group>
      </div>

      <!-- MENSAGEM -->
      <b-form-group
        :state="state.mensagem"
        label="Mensagem"
        label-for="mensagem"
        invalid-feedback="Mensagem é obrigatória"
      >
        <b-form-textarea
          id="mensagem"
          v-model="formulario.mensagem"
          :state="state.mensagem"
          style="height: 100px"
          required
        >
        </b-form-textarea>
      </b-form-group>
      <b-form-group class="text-right">
        <b-button
          @click="handleOk"
          :disabled="state.form.send"
          class="col-12 col-md-3 mr-0 mr-md-1 mb-1 mb-md-0"
          style="background-color: #152b5e"
          >Enviar</b-button
        >
        <b-button @click="clearForm" class="col-12 col-md-1">Limpar</b-button>
      </b-form-group>
    </form>

    <b-alert
      v-model="notificacaoEnviada"
      class="position-fixed fixed-bottom m-0 rounded-0"
      style="z-index: 2000; background-color: #152b5e"
      variant="border-0 text-light"
    >
      Mensagem enviada com sucesso!
    </b-alert>
  </div>
</template>

<script>
export default {
  data() {
    return {
      notificacaoEnviada: false,
      formulario: {
        nome: null,
        email: null,
        telefone: null,
        mensagem: null,
      },
      state: {
        nome: null,
        email: null,
        telefone: null,
        mensagem: null,
        form: {
          send: false
        }
      },
    };
  },
  methods: {
    clearForm() {
      this.formulario.nome = null;
      this.formulario.email = null;
      this.formulario.telefone = null;
      this.formulario.mensagem = null;

      this.state.nome = null;
      this.state.email = null;
      this.state.telefone = null;
      this.state.mensagem = null;
    },
    handleOk(modalEvent) {
      modalEvent.preventDefault();
      this.handleSubmit();
    },
    handleSubmit() {
      this.state.nome = !!this.formulario.nome;
      this.state.email = !!this.formulario.email;
      this.state.telefone = !!this.formulario.telefone;
      this.state.mensagem = !!this.formulario.mensagem;

      if (
        !this.state.nome ||
        !this.state.email ||
        !this.state.telefone ||
        !this.state.mensagem
      )
        return;

      this.enviarFormulario(this.formulario);
    },
    enviarFormulario(data) {
      this.state.form.send = true;
      this.$http
        .post("https://8sv1c2vc0b.execute-api.us-east-2.amazonaws.com/prod/enviar", {
          ...data,
          tipo: "faleconosco",
          assunto: `Contato`
        })
        .then((res) => res.json)
        .then(() => {
          this.notificacaoEnviada = true;
          this.state.form.send = false;
          setTimeout(() => {
            this.notificacaoEnviada = false;
          }, 5000);
          this.clearForm();
        })
        .catch(() => {
          this.state.form.send = false;
        });
    },
  },
  metaInfo: {
    title: "Fale Conosco",
  },
};
</script>

<style>
</style>